//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// eslint-disable-next-line camelcase
import OpenData from '@/views/fileStream/documentManage/weixin/openData'
import { init_wx } from '@/views/fileStream/documentManage/weixin/weixin'

import { documentBillAuditedPage, documentBillAuditPage } from '@/api/modular/fileStream/documentManage'
import { sysDictTypeDropDown } from '@/api/modular/system/dictManage'
import { STable } from '@/components'
import { stateColor } from '@/mixins/index.js'
import { handleAppointEleHeight } from '@/utils/common.js'
import { getEndColumns, satrtColumns } from './config'

export default {
    name: 'list',
    mixins: [stateColor],
    components: {
        STable,
        OpenData,
    },
    computed: {
        columns() {
            let arr = []
            const endColumns = getEndColumns(this.flow_approval_status, this.btck === 0)
            arr = [...satrtColumns, ...endColumns]
            if (this.btck === 1)
                arr = [
                    ...satrtColumns,
                    { title: '審批人', dataIndex: 'userId', scopedSlots: { customRender: 'userId' } },
                    ...endColumns,
                ]
            return arr
        },
    },
    data() {
        const formatDraftId = (id) => {
            if (!id) return []
            return JSON.parse(id)
        }
        return {
            btna: ['全部', '待我審核', '我已審核'], // '登記列表',
            btck: 0,
            // 查詢參數
            queryParam: { approvalTab: 0, documentType: 1 },
            // 加載數據方法 必須為 Promise 對象
            loadData: (parameter) => {
                if (this.btck === 3) {
                    const obj = Object.assign(parameter, this.queryParam)
                    return documentBillAuditedPage(obj)
                        .then((res) => {
                            const data = { ...res.data }
                            data.rows = data.rows.map((ele) => {
                                ele.draftId = formatDraftId(ele.draftId)
                                return ele
                            })
                            return data
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                }
                const { approvalStatus } = parameter
                let params = { ...parameter }
                if (approvalStatus && approvalStatus.length > 0)
                    params = { ...parameter, approvalStatus: approvalStatus[0] }
                return documentBillAuditPage(Object.assign(params, this.queryParam)).then((res) => {
                    const data = { ...res.data }
                    data.rows = data.rows.map((ele) => {
                        ele.draftId = formatDraftId(ele.draftId)
                        return ele
                    })
                    handleAppointEleHeight('#ygh-content-box', ['.box-btns', '.s-table-tool'], 66, res)
                    return data
                })
            },
            selectedRowKeys: [],
            selectedRows: [],
            flow_secrecy_degree: [],
            flow_urgency_degree: [],
            flow_file_type: [],
            flow_approval_status: [],
        }
    },
    watch: {
        '$route.query.index': {
            handler() {
                console.log(this.$route.query.index, '$route.query.index')
                this.change_status(Number(this.$route.query.index) || 0)
            },
            immediate: true,
        },
    },
    created() {
        this.getDict()
        init_wx()
    },
    mounted() {
        handleAppointEleHeight('#ygh-content-box', ['.box-btns', '.s-table-tool'], 66)
    },
    methods: {
        addApproval(record) {
            this.$refs.stepsForm.open({ type: 'approval', id: record.id })
        },
        Filter(arr, s) {
            const values = arr.filter((item) => item.code == s)
            if (values.length > 0) {
                return values[0].value
            }
        },
        /**
         * 獲取字典數據
         */
        getDict() {
            sysDictTypeDropDown({ code: 'flow_secrecy_degree' }).then((res) => {
                this.flow_secrecy_degree = res.data
            })
            sysDictTypeDropDown({ code: 'flow_urgency_degree' }).then((res) => {
                this.flow_urgency_degree = res.data
            })
            sysDictTypeDropDown({ code: 'flow_file_type' }).then((res) => {
                this.flow_file_type = res.data
            })
            sysDictTypeDropDown({ code: 'flow_approval_status' }).then((res) => {
                this.flow_approval_status = res.data
            })
        },
        todoApproval() {
            console.log(this.selectedRowKeys)
        },
        onSearch(value) {
            this.$refs.table.refresh(true)
        },
        onSelectChange(selectedRowKeys, selectedRows) {
            this.selectedRowKeys = selectedRowKeys
            this.selectedRows = selectedRows
        },
        change_status(i) {
            this.btck = i
            this.queryParam.approvalTab = i
            this.$refs.table && this.$refs.table.refresh(true)
        },
        edit(record) {
            this.$router.push({ path: '/fileStreamDocumentManage', query: { id: record.id } })
        },
        senpi(record) {
            const { approvalId, nodeId, id, documentType } = record
            this.noticeUpdate(id)
            this.$router.push({
                path: '/fileStream_Approval_Manage',
                query: { id, approvalId, nodeId, type: documentType },
            })
        },
        see({ id, documentType }) {
            this.$router.push({ path: '/fileStream_Approval_process', query: { id, type: documentType } })
        },
        // 去掉未通知提示
        noticeUpdate(id) {
            this.$store.dispatch('msg/noticeRefreshUpdate', { id }).then((res) => {
                if (res.code === 200) {
                    this.$store.dispatch('msg/unReadNums')
                }
            })
        },
    },
}
