import { getFilters } from '@/views/fileStream/documentManage/config'
export const satrtColumns = [
  {
    title: '處理箋名稱',
    dataIndex: 'name',
    ellipsis: true
  },
  {
    title: '標題',
    dataIndex: 'title',
    ellipsis: true
  },
  {
    title: '辦文/發文號',
    dataIndex: 'serialNumber',
    scopedSlots: { customRender: 'serialNumber' },
    ellipsis: true
  },
  // {
  //   title: '發文字號',
  //   dataIndex: 'documentNumber',
  //   ellipsis: true
  // },
  // {
  //   title: '來文機關',
  //   dataIndex: 'agency',
  // },
  // {
  //   title: '文件類型',
  //   dataIndex: 'fileType',
  //   scopedSlots: { customRender: 'fileType' },
  // },
  {
    title: '緊急程度',
    dataIndex: 'urgencyDegree',
    // width: 100,
    scopedSlots: { customRender: 'urgencyDegree' }
  },
  {
    title: '密級程度',
    dataIndex: 'secrecyDegree',
    // width: 100,
    scopedSlots: { customRender: 'secrecyDegree' }
  },

  {
    title: '擬稿單位/人',
    dataIndex: 'draftId',
    // width: 120,
    scopedSlots: { customRender: 'draftId' }
  },
  {
    title: '類型',
    dataIndex: 'documentType',
    // width: 150,
    scopedSlots: { customRender: 'documentType' }
  },
  {
    title: '擬稿日期',
    dataIndex: 'createTime',
    // width: 150,
    scopedSlots: { customRender: 'acceptTime' }
  }
]
export const getEndColumns = (data, isScreening = true) => [
  {
    title: '狀態',
    dataIndex: 'approvalStatus',
    scopedSlots: { customRender: 'approvalStatus' },
    filterMultiple: false,
    // width: 120,
    filters: isScreening && getFilters(data)
  },
  {
    title: '操作',
    // fixed:'right',
    dataIndex: 'action',
    // width: 240,
    scopedSlots: { customRender: 'action' }
  }
]
